import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaGalacticRepublic, FaHornbill, FaReact } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				Hakkımızda | PrimeDrive Rentals
			</title>
			<meta name={"description"} content={"PrimeDrive Rentals'ın üstün araç kiralama hizmetleri sunmaya olan bağlılığı hakkında daha fazla bilgi edinin."} />
			<meta property={"og:title"} content={"Hakkımızda | PrimeDrive Rentals"} />
			<meta property={"og:description"} content={"PrimeDrive Rentals'ın üstün araç kiralama hizmetleri sunmaya olan bağlılığı hakkında daha fazla bilgi edinin."} />
			<meta property={"og:image"} content={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"shortcut icon"} href={"https://brai-kzflor.com/img/435852341.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brai-kzflor.com/img/435852341.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brai-kzflor.com/img/435852341.png"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Hakkımızda
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					width="75%"
				>
					PrimeDrive Rentals'ın üstün araç kiralama hizmetleri sunmaya olan bağlılığı hakkında daha fazla bilgi edinin. Geniş araç filomuzu, esnek planlarımızı ve müşteri memnuniyetine olan bağlılığımızı keşfedin.
					<br />
					<br />
					PrimeDrive Rentals olarak, müşterilerimize en yüksek kalite ve güvenilirlik seviyesini sunmayı taahhüt eden lider araç kiralama hizmet sağlayıcısıyız. Sektörde yılların deneyimiyle, sorunsuz ve uygun maliyetli araç kiralama çözümleri arayan bireyler ve işletmeler için güvenilir bir ortak olarak kendimizi kanıtladık. Profesyonellerden oluşan ekibimiz, kiralama deneyiminizin olağanüstü olmasını sağlamak için çaba göstermektedir.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Misyonumuz ve Vizyonumuz
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Misyonumuz, müşterilerimizin beklentilerini aşan yüksek kaliteli araç kiralama hizmetleri sunmaktır. Çeşitli ihtiyaç ve tercihlere hitap eden geniş bir araç yelpazesi sunarak, herkesin yolculuğu için mükemmel aracı bulmasını sağlamak istiyoruz. Vizyonumuz, olağanüstü hizmeti, yenilikçi çözümleri ve müşteri memnuniyetine olan sarsılmaz bağlılığı ile tanınan bir araç kiralama şirketi olmaktır.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Her İhtiyaç İçin Esnek Kiralama Planları
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Sadece birkaç saatliğine mi, bir günlük mü yoksa uzun süreli mi bir araba lazım? Esnek kiralama planlarımız, programınıza göre düzenlenmiştir. Rekabetçi fiyatlar ve günlük, haftalık ve aylık kiralamayı içeren çeşitli paketler sunuyoruz. Amacımız, yolculuğunuzun tadını herhangi bir zorluk veya kısıtlama olmadan çıkarmanız için size esneklik sağlamaktır.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Olağanüstü Müşteri Desteği
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Dedike müşteri destek ekibimiz, karşılaşabileceğiniz herhangi bir soruya veya probleme yardımcı olmak için 7/24 hizmetinizdedir. Doğru aracı seçmekten yol yardımı sağlamaya kadar, kiralama deneyiminizin kusursuz ve stressiz olmasını sağlamak için buradayız. Memnuniyetiniz bizim önceliğimizdir ve her etkileşimde beklentilerinizi aşmayı hedefliyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://brai-kzflor.com/img/2-1.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Yola Çıkmaya Hazır mısınız?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				PrimeDrive Rentals'ın premium araç kiralama hizmetlerinin özgürlüğünü ve kolaylığını bugün deneyimleyin. Aşağıdaki düğmeye tıklayarak hemen bir araç rezervasyonu yapın ve yolculuğunuza şık bir başlangıç yapın!
				</Text>
				<Link
					href="/contact-us"
					padding="12px 44px 12px 44px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
					background="--color-primary"
				>
					Şimdi Rezervasyon Yap
				</Link>
			</Box>
		</Section>
		<Components.Footer13 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});